<template>
  <div class="inner-pages hd-white">
    <section
      style="text-align: center"
      v-if="blog"
      class="blog blog-section bg-white"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 blog-pots">
            <div class="row">
              <div class="col-md-12 col-xs-12">
                <div class="news-item details no-mb2">
                  <a href="blog-details.html" class="news-img-link">
                    <div class="news-item-img">
                      <img
                        style="object-fit: contain"
                        class="img-responsive"
                        v-lazyload
                        :data-url="`${blog.headerImage}`"
                        alt="blog image"
                      />
                    </div>
                  </a>
                  <div class="news-item-text details pb-0">
                    <span
                      ><h3 style="font-size: 45px; font-weight: 700">
                        {{ blog.title }}
                      </h3>
                      <p style="font-size: 18px">
                        {{ blog.createdAtDate }} /
                        <!-- <img
                        @click="$refs[`share-modal${blog.id}`].show()"
                        class="w-30 share-icon hover iconsetiing"
                        :src="
                          require('../../assets/images/icons/share-icon-green.webp')
                        "
                    /> -->

                        <!-- Social Icons -->

                        <ShareNetwork
                          network="facebook"
                          :url="`https://vencasa.com/blog/${blog.slug}`"
                          :title="blog.title"
                          :description="inner"
                          :quote="blog.title"
                          hashtags="vencasa"
                          class="mr-2"
                        >
                          <i
                            style="color: #548f4d"
                            class="fab fah fa-lg fa-facebook"
                          ></i>
                        </ShareNetwork>
                        <ShareNetwork
                          network="twitter"
                          :url="`https://vencasa.com/blog/${blog.slug}`"
                          :title="blog.title"
                          :description="inner"
                          :quote="blog.title"
                          hashtags="vencasa"
                          class="mr-2"
                        >
                          <i
                            style="color: #548f4d"
                            class="fab fah fa-lg fa-twitter"
                          ></i>
                        </ShareNetwork>
                        <ShareNetwork
                          network="linkedin"
                          :url="`https://vencasa.com/blog/${blog.slug}`"
                          :title="blog.title"
                          :description="inner"
                          :quote="blog.title"
                          hashtags="vencasa"
                          class="mr-2"
                        >
                          <i
                            style="color: #548f4d"
                            class="fab fah fa-lg fa-linkedin-in"
                          ></i>
                        </ShareNetwork>
                        <ShareNetwork
                          network="email"
                          :url="`https://vencasa.com/blog/${blog.slug}`"
                          :title="blog.title"
                          :description="inner"
                          :quote="blog.title"
                          hashtags="vencasa"
                          class="mr-2"
                        >
                          <i
                            style="color: #548f4d"
                            class="fas fa-lg fa-envelope"
                          ></i>
                        </ShareNetwork>

                        <!-- Social icon ends -->
                      </p></span
                    >
                    <div
                      style="text-align: left"
                      v-html="inner"
                      class="col-lg-12 col-md-12 pl-0"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <section class="leve-comments wpb">
              <h3 class="mb-5 text-left">
                Leave a Comment /
                <!-- <img
                        @click="$refs[`share-modal${blog.id}`].show()"
                        class="w-30 share-icon hover iconsetiing"
                        :src="
                          require('../../assets/images/icons/share-icon-green.webp')
                        "
                    /> -->
                <!-- Social Icons -->

                <ShareNetwork
                  network="facebook"
                  :url="`https://vencasa.com/blog/${blog.slug}`"
                  :title="blog.title"
                  :description="inner"
                  :quote="blog.title"
                  hashtags="vencasa"
                  class="mr-2"
                >
                  <i
                    style="color: #548f4d"
                    class="fab fah fa-lg fa-facebook"
                  ></i>
                </ShareNetwork>
                <ShareNetwork
                  network="twitter"
                  :url="`https://vencasa.com/blog/${blog.slug}`"
                  :title="blog.title"
                  :description="inner"
                  :quote="blog.title"
                  hashtags="vencasa"
                  class="mr-2"
                >
                  <i
                    style="color: #548f4d"
                    class="fab fah fa-lg fa-twitter"
                  ></i>
                </ShareNetwork>
                <ShareNetwork
                  network="linkedin"
                  :url="`https://vencasa.com/blog/${blog.slug}`"
                  :title="blog.title"
                  :description="inner"
                  :quote="blog.title"
                  hashtags="vencasa"
                  class="mr-2"
                >
                  <i
                    style="color: #548f4d"
                    class="fab fah fa-lg fa-linkedin-in"
                  ></i>
                </ShareNetwork>
                <ShareNetwork
                  network="email"
                  :url="`https://vencasa.com/blog/${blog.slug}`"
                  :title="blog.title"
                  :description="inner"
                  :quote="blog.title"
                  hashtags="vencasa"
                  class="mr-2"
                >
                  <i style="color: #548f4d" class="fas fa-lg fa-envelope"></i>
                </ShareNetwork>

                <!-- Social icon ends -->
              </h3>
              <div class="row">
                <div class="col-md-12 data">
                  <form action="#" @submit.prevent="">
                    <div class="col-md-12 form-group">
                      <textarea
                        v-model="message"
                        class="form-control"
                        id="exampleTextarea"
                        rows="8"
                        placeholder="Message"
                        required=""
                      ></textarea>
                    </div>
                    <button
                      @click="makeComment"
                      type="submit"
                      class="btn btn-primary btn-lg mt-2"
                    >
                      Submit Comment
                    </button>
                  </form>
                </div>
              </div>
            </section>
            <section ref="comments" class="comments">
              <h3 class="mb-5 text-left">
                {{ blog.comments.length }} Comments
              </h3>
              <comment
                v-for="comment in blog.comments"
                :key="comment.id"
                :comment="comment"
              ></comment>
            </section>
          </div>
        </div>
      </div>
    </section>
    <b-modal
      @shown="setWidth"
      hide-footer
      centered
      scrollable
      :ref="`share-modal${blog.id}`"
      :id="`share-modal${blog.id}`"
      title="Share"
    >
      <ul style="list-style: none; padding: 0px">
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="facebook"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-facebook"></i>
            Facebook
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="twitter"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-twitter"></i>
            Twitter
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="linkedin"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fab fah fa-lg fa-linkedin-in"></i>
            LinkedIn
          </ShareNetwork>
        </li>
        <li
          class="pb-2 pt-2 d-flex justify-content-center"
          style="border-bottom: 1px solid grey"
        >
          <ShareNetwork
            network="email"
            :url="`https://vencasa.com/blog/${blog.slug}`"
            :title="blog.title"
            :description="inner"
            :quote="blog.title"
            hashtags="vencasa"
          >
            <i style="color: #548f4d" class="fas fa-lg fa-envelope"></i> Email
          </ShareNetwork>
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const comment = () =>
  import(/* webpackChunkName: "comment" */ "../../components/comment");
import Quill from "quill";
import _ from "lodash";

export default {
  name: "blog-description",
  data() {
    return {
      inner: "",
      blog: null,
      message: "",
      user: null,
    };
  },
  components: {
    comment,
  },
  computed: {
    getTopFiveProperties() {
      return this.properties.properties
        ? this.properties.properties.slice(0, 5)
        : [];
    },
    ...mapGetters({
      popularTags: "getPopularTags",
      categories: "getCategories",
      properties: "getProperties",
    }),
  },
  methods: {
    setWidth() {
      document.querySelector(
        `#share-modal${this.blog.id}___BV_modal_content_`
      ).style.width = "300px";
    },
    getProfilePic(pic) {
      try {
        let pic = JSON.parse(this.user.profilePic);
        return pic[0];
      } catch (err) {
        return this.user.profilePic;
      }
    },
    getUser(id) {
      this.$store.dispatch("getBuyerSellerForBlog", id).then((res) => {
        if (res.success) {
          this.user = res.data;
        }
      });
    },
    makeComment() {
      const data = {
        blogId: this.blog.id,
        comment: this.message,
      };
      this.$store.dispatch("createComment", data).then((res) => {
        if (res == true) {
          this.$store.dispatch("blog", this.$route.params.slug).then((res) => {
            if (res.data.success) {
              this.blog = res.data.data;
              this.message = "";
              this.$refs.comments.scrollIntoView({ bahavior: "smooth" });
            } else {
              alert("error in fetching blog");
            }
          });
        }
      });
    },
  },
  async mounted() {
    this.$store.commit("setShowLoader", true);
    this.$store.dispatch("blog", this.$route.params.slug).then((res) => {
      this.$store.commit("setShowLoader", false);
      if (res.data.success) {
        this.blog = res.data.data;
        this.getUser(this.blog.userId);
        let article = document.createElement("article");
        let quill = new Quill(article, { readOny: true });
        quill.setContents(JSON.parse(this.blog.description));
        this.inner = quill.root.innerHTML;
      } else {
        alert("error in fetching blog");
      }
    });
    if (!this.categories) this.$store.dispatch("categories");
    if (!this.popularTags) {
      this.$store.dispatch("popularTags");
    }
    if (_.isEmpty(this.properties)) {
      await this.$store.dispatch("getAllProperties", {
        currentPage: 1,
        perPage: 5,
        sortItem: "id",
        sortOrder: "desc",
        search: "",
      });
    }
  },
};
</script>

<style scoped src="../../assets/css/fontawesome-all.css"></style>
<style scoped src="../../assets/css/font-awesome.min.css"></style>
<style scoped src="../../assets/css/bootstrap.min.css"></style>
<style scoped src="../../assets/css/styles.css"></style>

<style scoped>
div >>> p {
  margin: 0;
  white-space: break-spaces;
}

.w-30 {
  height: 20px;
  width: 20px;
}
.fa-lg {
  font-size: 1.13333333em !important;
}

@media (min-width: 300px) and (max-width: 700px) {
  .iconsetiing {
    width: 20px !important;
    height: 20px;
  }
}
</style>
